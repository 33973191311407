.o-block-info {

    ul {
        list-style-type: none;
        padding-left: 45px;
    
        li {
            position: relative;
    
            &::before {
                content: '';
    
                position: absolute;
                top: 3px;
                left: -45px;
    
                width: 20px;
                height: 15px;
    
                background: url(../img/svg/plane.svg) center center no-repeat;
    
            }
    
            h6 {
                margin-bottom: $o-xs;
            }
        }
    }
    
    ol {
        list-style-type: none;
        margin-top: $g-sm;
        padding-left: 45px;
        counter-reset: number;
        
        li {
            position: relative;
    
            &::before {
                counter-increment: number;
                content: counter(number);
        
                position: absolute;
                top: 0;
                left: -45px;
        
                width: 30px;
                height: 30px;
        
                border: 2px solid $orange;
                border-radius: 50%;
        
                display: flex;
                justify-content: center;
                align-items: center;
        
                font-size: 1.4rem;
                font-weight: $font-weight-middle;
        
            }
        }
    }
}
.categories-store-wrap {

    ul {
        padding-left: 0;
        li {
            &::before {
                display: none;
            }
        }
    }
}

@include media-breakpoint-up (xs) {

    .o-block-info {
        ul {
    
            li {
                font-size: 1.4rem;
                margin-bottom: $o-sm;
            }
        }
    
        ol {
            
            li {
                font-size: 1.4rem;
                margin-bottom: $o-sm;
            }
        }
    }

}

@include media-breakpoint-up (md) {

    .o-block-info {
        ul {
    
            li {
                font-size: $font-size-base-my;
                margin-bottom: $g-sm;
            }
        }
    
        ol {
            
            li {
                font-size: $font-size-base-my;
                margin-bottom: $g-sm;
            }
        }
    }

}