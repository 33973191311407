.for-partners {
	position: relative;
	background: url(../img/_dots-cirle_pattern-right.png) right bottom no-repeat;

	.flex-container-element {
		align-items: center;

		.col-left {

			.video-wrap {

				.video-content-wrap {
					background: url(../img/for-partners-video-bg.png);
					width: 100%;
					max-width: 635px;
				}
			}
		}

		.col-right {
			
			.o-block-info {
				max-width: 660px;
			}
		}

	}

	h4.title {
		margin-top: 30px;
		margin-bottom: 20px;

	}

	.how-b2b-wrap {
		display: flex;
		align-items: center;
		flex-direction: row;
		max-width: 635px;

		margin-top: 60px;
		font-size: $font-size-base-my;

		.how-b2b{
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.how-b2b-info {
			max-width: 455px;
			font-size: $font-size-base-my;
		}
	}

}

@include media-breakpoint-down (xl) {

	.for-partners {

		.flex-container-element {

			.col-left {
				width: 45%;

				.video-wrap {

					.video-content-wrap {
					}

				}

				.how-b2b-info {
					font-size: 1.4rem;
				}

			}

			.col-right {
				width: 55%;

				.o-block-info {
					padding-left: $g-lg;

				}
			}
		}
	}

}

@include media-breakpoint-down (lg) {

	.for-partners {
		background: none;

		.flex-container-element {
			flex-direction: column-reverse;

			.col-left {
				width: 100%;

				display: flex;
				justify-content: center;
				flex-direction: column;


				.video-wrap {
					margin: 0 auto;

					.video-content-wrap {
					}

				}

				.how-b2b-wrap {
					justify-content: center;
					margin: $o-lg auto 0;

					.how-b2b-info {
						font-size: 1.4rem;

					}
				}
			}

			.col-right {
				width: 100%;

				display: flex;
				justify-content: center;
				padding-bottom: $o-lg;

				.o-block-info {
					padding-left: 0;

					max-width: 635px;

				}
			}
		}
	}

}

@include media-breakpoint-down (sm) {

	.for-partners {

		.flex-container-element {

			.col-right {
				padding-bottom: $g;
			}
		}
	}

}

@include media-breakpoint-down (xs) {

	.for-partners {

		.flex-container-element {

			.col-left {
				font-size: 1.4rem;

				.how-b2b-wrap {
					flex-direction: column;

					margin-top: $g;

					.how-b2b {
						display: block;

						margin: 0 auto $g-sm;
						
					}
				}
			}

			.col-right {
				padding-bottom: $o-lg;

				.o-block-info {

					.btn-wrap {
						margin-top: $o;

						.btn {
							margin-top: 15px;
						}
					}
				}
			}
		}
	}

}

