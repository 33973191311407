.catalog {
	background: url(../img/_dots-cirle_pattern-right.png) right bottom no-repeat;

	&-wrap {
		padding-top: $g-sm;

		display: flex;
		flex-direction: row;

		margin-left: -$g-sm;
		margin-right: -$g-sm;

		&-block {
			width: 30%;
			max-width: 340px;

			padding: $g-sm;
		}

		&-list {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}

		&-link {
			max-width: 340px;

			h4.title {
				color: $text-color;
				transition: $transition;
			}

			&:hover h4.title {
				color: $link-color-hover;
			}
		}

		&-item {
			margin-bottom: $g-sm;

			border-radius: $border-radius;

			overflow: hidden;

			box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.1);
			transition: $transition;

			&:hover {
				box-shadow: 0px 6px 18px 0px rgba(0,0,0,0.1);
			}
		}
	}

}

@include media-breakpoint-down (lg) {

	.catalog {

		&-wrap {
			padding-top: 0;

			&-link {
				max-width: 340px;

				margin-right: $o;
			}
		}
	}

}

@include media-breakpoint-down (md) {

	.catalog {

		&-wrap {
			padding-top: 0;

			&-block {
				width: 50%;
			}

			&-list {
				margin-right: -$g-sm;
				margin-left: -$g-sm;
			}

			&-link {
				max-width: 300px;

				margin: $o-sm;
				margin-bottom: $o;
			}
		}
	}

}

@include media-breakpoint-down (sm) {

	.catalog {

		&-wrap {
			margin-left: -10px;
			margin-right: -10px;

			&-block {
				padding: 10px;
			}

			&-link {
				max-width: 350px;

				margin-bottom: $o;
			}
		}
	}

}

@include media-breakpoint-down (xs) {

	.catalog {

		&-wrap {
			margin-top: -10px;
			
			&-block {
				max-width: 100%;
			}
		}
	}

}

@media screen and (max-width: 380px) {

	.catalog {

		&-wrap {

			&-block {
				width: 100%;
			}
		}
	}

}