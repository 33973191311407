.news {
	background: url(../img/wave.png) left bottom no-repeat ,
					$section-radial-gradient;
	background-size: 100%;


	&-wrap {
		position: relative;
		padding-bottom: 50px;

		.news-slider-btns {
			height: 50px;
			position: absolute;
			left: 0;
			right: 0;
			bottom: -50px; 

			.wrap {
				position: relative;
				width: 100%;

				.swiper-btn-wrap {
					position: absolute;
					top: 0;
					left: -10px;

					.swiper-btn-prev-news {
						margin-right: 0;
					}

					.news-btn-svg,
					.slide-btn-circle-svg {
						fill: $white;
					}
					
					.slide-btn-circle-svg {
						stroke: $white;
						transition: .2s;
					}

					.slide-btn-circle-svg:active {
						stroke: $orange;
					}

				}

				.btn {
					margin: 0;
				}

			}
			
		}

	}

	.btn {

		&-white {

			&:before {
				background: $pink;
			}
			
		}

	}

}

.img-wrap {

	.news-block {
		transition: $transition;

		&:hover .news-image {
			box-shadow: 0px 15px 30px 0px rgba(128,64,0,0.1);
		}

		&:hover h3.title {
			color: $orange;
		}
	}

}


.news-image {
	box-sizing: border-box;

	border: 15px solid $white;
	border-radius: $border-radius;

	box-shadow: 0px 3px 8px rgba(128, 64, 0, 0.08);

	margin-bottom: $o;

	transition: $transition;

	img {
		width: 100%;
		max-height: 100%;
	}
}

@include media-breakpoint-down (xl) {

	.news {

		.flex-container {
			padding-bottom: 140px;
		}
	
		&-wrap {
			padding-bottom: $o;
		}	

	}

}

@include media-breakpoint-down (lg) {
	.news {

		.flex-container {
			padding-bottom: 150px;
		}

	}

	.news-image {
		margin-bottom: $o-sm;
		border: 10px solid $white;
	}	

}

@include media-breakpoint-down (md) {

	.news {

		.flex-container {
			padding-bottom: 130px;
		}
	}

}

@include media-breakpoint-down (sm) {

	.news {

		.flex-container {
			padding-bottom: 150px;
		}

		&-wrap {
			display: flex;
			flex-direction: column;

			padding-bottom: 0;

			.news-slider-btns {
				position: static;

				.wrap {
					margin-top: $g-sm;

					.swiper-btn-wrap {
						position: static;

						margin-bottom: $o;
					}

				}

			}

		}

	}

}

@include media-breakpoint-down (xs) {

	.news {

		.flex-container {
			padding-bottom: 50px;
		}

		&-wrap {

			.news-slider-btns {
				margin-top: $g-sm;

				.wrap {
					margin-top: 0;

					.swiper-btn-wrap {

						.news-btn-svg {
							width: 40px;
						}

					}

				}

			}

		}

	}

} 
@media screen and (max-height: 840px) and (min-height: 760px) and (min-width: 1440px) {
	.news {

		.flex-container {
			padding-top: 160px;
		}
	}
}

@media screen and (max-height: 930px) and (min-height: 840px) and (min-width: 1440px) {

	.news {

		&-wrap {
			padding-bottom: $g-sm;
		}
	}

}