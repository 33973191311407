.our-products {
	background: url(../img/_dots-cirle.png) 20% 95% no-repeat;

	.item-products {
		cursor: zoom-in;
	}

	.products-slider {
		position: relative;

		.swiper-products-wrap {
			position: relative;

			.categories {
				position: absolute;
				right: calc(45% - 15px);

				z-index: 10;

				&-link {
					display: block;

					text-decoration: none;
					color: $gray;
					font-size: $font-size-base-my;
					font-weight: $font-weight-middle;

					margin-bottom: 15px;

					transition: $transition;

					&:hover{
						color: $orange;
					}

					&.swiper-pagination-bullet{
						background: none;

						width: 200px;
						height: auto;

						opacity: 1;

						&.swiper-pagination-bullet-active{
							color: $orange;
						}
					}
				}
			}
		}

		
		.swiper-slide-products {

			.col-wrap-products-left {

				position: relative;

				width: 55%;
			
				.products-img-wrap{
					max-width: 905px;

				}
			}

			.col-wrap-products-right{
				width: 45%;
			}
		}

		.o-block-info {
			padding-right: 0;
			padding-left: 155px;

			&.products-text{
				box-sizing: padding-box;

				.list {
					margin-bottom: $g;

					&-item{
						margin-top: $g-sm;
					}
				}
			}
		}

		.swiper-btn-wrap {
			position: absolute;
			top: 635px;
			left: 0;
			
			z-index: 5;
		}
	}

}

@include media-breakpoint-down (laptop) {

	.our-products {

		.products-slider {

			.swiper-slide-products {

				.col-wrap-products-left {

					.flex-container-element {
						flex-direction: row;
					}
				}
			}

			.swiper-products-wrap {
				.categories {
					&-link {
						&.swiper-pagination-bullet {
							width: 195px;
						}
					}
				}
			}

			.o-block-info{
				padding-left: 100px;

			}

			.swiper-btn-wrap {
				top: 550px;
			}
		}
	}

}

@include media-breakpoint-down (xl) {

	.our-products {
		background: url(../img/_dots-cirle.png) 20% 60% no-repeat;

		.products-slider {

			.swiper-slide-products {

				.col-wrap-products-left {
					width: 50%;
				}

				.col-wrap-products-right {
					width: 50%;
				}
			}

			.swiper-products-wrap {

				.categories {
					right: calc(50% - 15px);

					&-link {
						font-size: 1.3rem;
						margin-bottom: $o-xs;
						margin-left: 0;

						&.swiper-pagination-bullet {
							width: 145px;
						}
					}
				}

			}

			.big-vertical-img {
				margin-right: $o;
			}

			.img-right-block {

				.middle-img {
					max-height: 220px;
				}

				.big-horizontall-img {
					max-height: 202px;
					overflow: hidden;
					margin-top: $o;
				}

			}

			.o-block-info {

				&.products-text {
					padding-left: $g-lg;
				}
			}

			.swiper-btn-wrap {
				top: 400px;
			}
		}
	}

}

@include media-breakpoint-down (lg) {

	.our-products {
		background: url(../img/_dots-cirle.png) 29% 45% no-repeat;

		.products-slider {

			.swiper-slide-products {

				.col-wrap-products-left {
					margin-bottom: $o-lg;

					width: 100%;
				
					.products-img-wrap{
						max-width: 905px;
					}
				}

				.col-wrap-products-right {
					width: 100%;

					.o-block-info {
						max-width: 80%;

						padding-left: 0;
					}
				}
			}

			.swiper-products-wrap {

				.categories {
					right: 10px;

					&-link {
						font-size: $font-size-base-my;
						margin-bottom: $g-sm;

						&.swiper-pagination-bullet {
							width: 250px;
						}
					}
				}
			}

			.big-vertical-img {
				margin-right: $o;
			}

			.img-right-block {
				width: 510px;

				.middle-img {
					max-height: 300px;
				}

				.big-horizontall-img {
					max-height: 300px;
				}

				}

			.o-block-info {

				&.products-text {
					padding-left: $g-lg;
				}
			}

			.swiper-btn-wrap {
				top: 580px;
			}
		}
	}

}

@include media-breakpoint-down (md) {

	.our-products {
		background: none;

		.products-slider {

			.swiper-products-wrap {

				.categories {
					right: 0;

					&-link {
						margin-right: 0;
						margin-bottom: $o-xs;

						&.swiper-pagination-bullet {
							width: 180px;
						}
					}
				}
			}

			.img-right-block {

				.middle-img {
					max-height: 274px;
				}

				.big-horizontall-img {
					max-height: 252px;
				}
			}

			.swiper-btn-wrap {
				top: 508px;
			}
		}
	}

}

@include media-breakpoint-down (sm) {

	.our-products {

		.flex-container {
			padding-bottom: 70px;
		}

		.products-slider {

			.swiper-products-wrap {

				.categories {

					&-link {
						font-size: 1.2rem;
						margin-bottom: $o-xs;

						&.swiper-pagination-bullet {
							width: 140px;
						}
					}
				}
			}

			.big-vertical-img {
				margin-right: $o-xs;
			}

			.img-right-block {

				.middle-img {
					max-height: 206px;
				}

				.big-horizontall-img {
					max-height: 190px;

					margin-top: $o-xs;
				}

			}

			.swiper-btn-wrap {
				top: 356px;
			}
		}
	}

}

@include media-breakpoint-down (xs) {

	.our-products {

		.flex-container {
			padding-bottom: $g-sm;
		}

		.products-slider {

			.swiper-slide-products {

				.col-wrap-products-left {
					margin-bottom: $g;

					.img-left-block {
						display: none;
					}

					.img-right-block {
						width: 100%;

						.middle-img {
							max-height: 330px;
						}

						.big-horizontall-img {
							max-height: 309px;

							margin-top: $o-xs;

							min-width: 100%;

							img {
								min-width: 100%;
							}
						}
					}
				}

				.col-wrap-products-right {
					margin-bottom: $g-sm;

					.o-block-info {
						max-width: 90%;
					}
				}
			}

			.swiper-products-wrap {

				.categories {

					&-link {
						font-size: 1.3rem;

						&.swiper-pagination-bullet {
							width: 215px;
						}
					}
				}
			}

			.swiper-btn-wrap {
				display: none;
			}
		}
	}

}

@media screen and (max-width: 400px) {

	.our-products {

		.products-slider {

			.swiper-products-wrap {

				.col-wrap-products-right {

					.o-block-info {
						max-width: 100%;
					}

				}


				.categories {

					&-link {
						font-size: 1.1rem;

						&.swiper-pagination-bullet {
							width: 140px;
						}
					}
				}
			}
		}
	}

}