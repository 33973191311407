p {
	font-size: $font-size-base-my;

	line-height: 1.6;
}

.js-one-img {
	transition: .5s;

	&.hide {
		opacity: 0;
		transition: .5s;
	}

}

.js-two-img {
	transition: .5s;

	&.hide {
		opacity: 0;
	}

}

.js-three-img {
	transition: .5s;

	&.hide {
		opacity: 0;
	}

}

.js-four-img {
	transition: .5s;

	&.hide {
		opacity: 0;
	}

}

// links
a,
a:active,
a:focus,
a:visited{
	text-decoration: none;
	color: $link-color-normal;
}
a:hover{
	text-decoration: none;
	color: $link-color-hover;
}
.link-web-site {
	color: $orange;
}

.breadcrumb {
	background: none;

	&-item {
		font-size: $font-size-base-my;
	
	}
}

.breadcrumb-item + .breadcrumb-item::before {
	color: #999;
}
	
a.link-page {
	font-size: $font-size-base-my;
	color: #999;
	font-weight: $font-weight-normal;

	&:hover {
		color: $link-color-hover;
	}

	&-active {
		color: $orange;
	}

	&-middle {
		font-weight: $font-weight-middle;
	}

}

.item-gallery {
	cursor: zoom-in;
}

	
.link-advices {
	color: $orange;
	font-size: $font-size-base-my;
}

// date
.date {
	font-size: $font-size-base-my;
	color: #999;
	font-weight: $font-weight-light;
}

.title {
	font-weight: $font-weight-bold;
}

h1.title {
	font-size: $h1-font-size;
	font-weight: $font-weight-bold;

	max-width: 600px;
}

h2 {
	font-size: $h2-font-size;
	margin-bottom: $g-sm;
}

h2.title-big {
	font-size: $h2-font-size-big;
}

h3 {
	font-size: $h3-font-size;
	transition: $transition;
	color: $text-color;
}

h4 {
	font-size: $h4-font-size;
}

h6 {
	font-size: $h6-font-size;
}

.wrapper {
	padding-top: $g * 4;

	margin-left: -10px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.overlay {
	position: absolute;
}

.overlay-dots {
	top: 50%;
	transform: translateY(-50%);
}

.overlay-dots-left {
	left: 0;
}

.overlay-dots-right {
	right: 0;
}

.screen-wrap {
	width: 100%;
	max-width: 100%;
}

.col-wrap {
	width: 50%;
}

.flex-container {
	display: flex;
	align-items: center;
	height: 100vh;

	position: relative;

	padding-top: 100px;
}

.flex-container-element {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

@include media-breakpoint-down (laptop) {

	.date{
		font-size: 1.4rem;
	}

	h2.title-big {
		font-size: 4.2rem;
	}

}

@include media-breakpoint-down (xl) {

	h1.title {
		font-size: 4.2rem;
	}

	h2 {
		font-size: 3.8rem;
	}

	h2.title-big {
		font-size: 4rem;
	}

	h3 {
		font-size: 2.8rem;
	}

	.flex-container {
		height: auto;

		padding-top: 100px;
		padding-bottom: 100px;
	}

} 

@include media-breakpoint-down (lg) {

	h1.title {
		font-size: 3.8rem;

		max-width: 350px;
	}

	h2 {
		font-size: 3.4rem;
	}

	h3 {
		font-size: 2rem;
	}

	.wrapper {
		padding-top: 140px;
	}

	.col-wrap {
		width: 100%;
	}

	.flex-container-element {
		flex-direction: column;
	}

	.swiper-slide {

		.flex-container-element {
			flex-direction: row;
		}
	}

}

@include media-breakpoint-down (md) {

	h1 {
		font-size: 3.8rem;
	}

	h2 {
		font-size: 2.8rem;
		margin-bottom: $o-sm;
	}

	h2.title-big {
		font-size: 2.8rem;
	}

	h3 {
		font-size: 1.8rem;
	}

	h4 {
		font-size: 1.6rem;
	}

	.flex-container {
		padding-top: $g-lg;
		padding-bottom: $g-lg;
	}
}

@include media-breakpoint-down (sm) {

	p {
		font-size: 1.4rem;

		line-height: 1.5;
	}

	.date {
		font-size: 1.2rem;
	}

	.link-advices {
		font-size: 1.2rem;
	}

	a.link-page {
		font-size: 1.4rem;
	}

	.wrapper {
		padding-top: 100px;
	}

	h1.title {
		font-size: 3.2rem;

		max-width: 300px;
	}

	h2 {
		font-size: 2.4rem;
	}

	h2.title-big {
		font-size: 2.4rem;
	}

}

@include media-breakpoint-down (xs) {

	.wrapper {
		flex-direction: column;

		padding-top: $g-lg;

		.head {
			margin-top: $o;
		}
	}

	.flex-container {
		padding: 50px 20px;
	}

	h1.title {
		font-size: 2.4rem;

		max-width: 300px;

	}

	h2 {
		font-size: 2rem;
	}

	h2.title-big {
		font-size: 2rem;
	}

}

@media screen and (max-height: 840px) and (min-width: 1440px) {

	.flex-container {
		height: auto;

		padding: 150px 20px;
	}

}