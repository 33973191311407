.online-store {
	background: url(../img/wave.png) left bottom no-repeat ,
					url(../img/_dots-pattern-white.png) right center no-repeat ,
					$section-radial-gradient;
	background-size: 100%, 32%;

	position: relative;

	.o-block-info {
		font-weight: $font-weight-normal;

		.categories-store-wrap {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-top: 40px;

			.list-categories-store {
				display: flex;
				flex-wrap: wrap;
			
				.list-item-categories-store {
					position: relative;
					margin-bottom: 25px;
					font-size: $font-size-base-my;
					padding-left: 45px;
					width: 50%;
					box-sizing: padding-box;

					.img-item {
						width: 25px;

						position: absolute;
						left: 0;
						top: 50%;

						transform: translateY(-50%);

					}
				}
			}
		}

		.col-right {
			display: block;
		}

	}

	.cards-wrap{
		position: relative;

		.cards-parallax_2{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
	
		}

		.cards-parallax_1{
			position: absolute;
			top: -20px;
			left: 50px;

			z-index: 1; 
		}
	}

}

@include media-breakpoint-down (xl) {

	.title-store {
		max-width: 500px;
	}

}

@include media-breakpoint-down (lg) {

	.title-store {
		max-width: 440px;
	}

	.online-store {

		.col-left {
			width: 60%;
		}

		.col-right {
			width: 40%;
		}
	}

}

@include media-breakpoint-down (md) {

	.online-store {

		.col-left {
			width: 100%;

			.o-block-info {

				h2.title {
					max-width: 360px;
				}
			}
		}
		.col-right{
			display: none;
		}
	}

}

@include media-breakpoint-down (sm) {

	.online-store {

		.col-left {

			.o-block-info {

				h2.title {
					max-width: 300px;
				}
			}
		}
	}

}

@media screen and (max-width: 500px) {

	.online-store {

		.o-block-info {

			.categories-store-wrap {
				
				.list-categories-store {

					.list-item-categories-store {
						width: 100%;
					}
				}
			}
		}
	}

}

@include media-breakpoint-down (xs) {

	.online-store {
		background-size: 100%, 50%;

		.col-left {

			.o-block-info {

				h2.title {
					max-width: 250px;

				}

				.categories-store-wrap {
					flex-direction: column;

					.list-categories-store {

						.img-item{
							visibility: visible;

						}

						&:first-child {
							margin-bottom: 25px;
						}
					}
				}
			}
		}
	}

}

@media screen and (max-height: 840px) and (min-height: 760px) and (min-width: 1440px) {

	.online-store {
		.flex-container {
			padding-top: 130px;
		}
	}

}