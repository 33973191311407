.c-banner{
	position: fixed;
	left: 0;
	right: 0;
	top: 0;

	height: 150px;

	display: flex;
	align-items: center;

	transition: $transition;

	z-index: 1000;
}

.banner-bg {
	
	&.is-scroll {
		background-color: $white;
	
		height: 100px;
		box-shadow: 0px 3px 20px 0px rgba(128, 64, 0, 0.08);
	}

}

@include media-breakpoint-down (xl) {

	.main-banner-bg {

		&.is-scroll {
			background-color: $white;
		
			height: 100px;
			box-shadow: 0px 3px 20px 0px rgba(128, 64, 0, 0.08);
		}		

	}

}

@include media-breakpoint-down (lg) {

	.c-banner {
		align-items: flex-start;

		transition: .5s;

		&.show-menu {
			overflow: visible;

			height: 100vh;

			padding-bottom: $g;

			background-color: $white;
			box-shadow: 0px 3px 20px 0px rgba(128, 64, 0, 0.08);
			transition: $transition;
		}
	}

}

@media screen and (max-height: 840px) {

	.main-banner-bg {

		&.is-scroll {
			background-color: $white;
		
			height: 100px;
			box-shadow: 0px 3px 20px 0px rgba(128, 64, 0, 0.08);
		}		
	}

	.main-banner-bg {
		
		&.is-scroll {
			height: 100px;
		}		
	}

}

@include media-breakpoint-down (sm) {


	.banner-bg {
		
		&.is-scroll {
			height: 80px;
		}
	}

	.main-banner-bg {
		
		&.is-scroll {
			height: 80px;
		}		
	}

	.c-banner {
		height: 80px;

		&.show-menu {

			height: 100vh;
		}
	}

}
