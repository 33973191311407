.plyr__control--overlaid{
	background: linear-gradient(to right, #FFAC56, #ff7e33);
	padding: 20px;

	transition: $transition;
}

.plyr--video .plyr__control.plyr__tab-focus{
	background: linear-gradient(to right, #FFAC56, #ff7e33);
}

.plyr__control.plyr__tab-focus{
	box-shadow: 0 0 0 5px rgba(255, 172, 86, 0.5)
}

.plyr--video .plyr__control:hover{
	background: linear-gradient(to right, #FFAC56, #ff7e33);
}

.plyr__controls {
	opacity: 0;
	visibility: hidden;

	&.shown {
		opacity: 1;
		visibility: visible;
	}
}

.plyr--full-ui input[type=range]{
	color: $orange;
}

.plyr__video-embed {
    display: block !important;
}