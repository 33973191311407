.news-all {
	background: url(../img/_dots-cirle_pattern-right.png) 100% 32% no-repeat ,
					url(../img/_dots-cirle_pattern-left.png) 0 100% no-repeat;
	
	&-wrap {
		position: relative;

		padding-top: $g;
		padding-bottom: $o-lg;

		.img-wrap {
			display: flex;
			flex-wrap: wrap;

			.news-block {
				margin-bottom: $o * 3;

				h3.title {
					margin-bottom: $g-sm;
				}
			}
		}

		.pagination-page {
			margin-top: $g-sm;

			.pagination {
				// position: relative;

				padding: 0 $o-lg;

				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;

				.page-link {
					display: flex;
					align-items: center;
					justify-content: center;

					width: 50px;
					height: 50px;

					background: none;
					color: $text-color;

					font-size: $font-size-base-my;
					font-weight: $font-weight-middle;

					margin: 0 5px;

					border: 2px solid $btn-color-normal;
					border-radius: 50%;

					box-shadow: none;

					&.next,
					&.prev {

						width: auto;
						height: auto;

						border: none;

						margin: 0;
						padding: 0;

						&:hover,
						&:focus {
							border: none;
						}
					}

					&.prev {
						margin-right: $o-xs;
					}

					&.next {
						margin-left: $o-xs;
					}

					.swiper-btn {
						margin: 0;
					}

					&:hover,
					&:focus {

						background: none;
						color: $text-color;

						border: 2px solid $orange;
					}

				}

				.page-item {
					&.active {
						.page-link{
							border-color: #cccccc;
							color: $orange;
						}
					}
				}

			}

		}

	}

}

@include media-breakpoint-down (laptop) {

	.news-all {
	
		&-wrap {

			.img-wrap {

				.news-block {
					margin-bottom: $o-lg;

					.news-image {
						margin-bottom: $o;
					}

					h3.title {
						margin-bottom: $o-xs;
					}

				}

			}

		}

	}

}

@include media-breakpoint-down (xl) {

	.news-all {
	
		&-wrap {

			.img-wrap {

				.news-block {
					
					.news-image {
						margin-bottom: $g-sm;
					}

				}

			}

		}

	}

}

@include media-breakpoint-down (lg) {

	.news-all {
	
		&-wrap {
			padding-top: $o;
			padding-right: 10px;
			padding-left: 10px;

			.img-wrap {

				.news-padding {
					padding-left: $o-xs;
					padding-right: $o-xs;

					.news-block {
						margin-bottom: $g;

						.news-image {
							margin-bottom: $o-sm;
						}

					}
					
				}

			}

		}

	}

} 

@include media-breakpoint-down (md) {

	.news-all {
		background-position: 100% 32%, 0 85%;

		&-wrap {
			padding-bottom: $g;
			
		}

	}

}

@include media-breakpoint-down (sm) {

	.news-all {
	
		&-wrap {
			padding-top: $o-xs;
			padding-bottom: $g-sm;

			.img-wrap {

				.news-block {
					margin-bottom: $o-lg;

				}

			}

			.pagination-page {

				.pagination {
					position: relative;

					.page-link {
						margin-bottom: 5px;

						&.next,
						&.prev {
							position: absolute;
							top: 0;
						}

						&.prev {
							left: 0;
						}

						&.next {
							right: 0;
						}

					}

				}

			}

		}

	}

}

@include media-breakpoint-down (xs) {

	.news-all {
		background: none;

		&-wrap {
			padding-top: 0;

			.img-wrap {

				.news-padding {

					.news-block {
						margin-bottom: $o;

						.news-image {
							margin-bottom: $o-sm;
						}

					}

				}

			}

			.pagination-page {

				.pagination {
					padding: 0 50px;

					.page-link {

						width: 40px;
						height: 40px;

						font-size: 1.4rem;
						font-weight: $font-weight-middle;

						margin: 0 5px 5px;

						.swiper-btn {
							margin: 0;

							svg {
								width: 40px;
								height: 40px;
							}
						}

					}

				}

			}

		}

	}

}
