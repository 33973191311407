$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 996px,
    xl: 1240px,
    laptop: 1440px,
    desktop: 1660px,
    // fullhd: 1800px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    laptop: 1380px,
    desktop: 1620px,
    // fullhd: 1740px
);

$layout-min-width:                                  320px;
$layout-max-width:                                  1920px;
$grid-gutter-width:                                 40px;

$g:                                                 $grid-gutter-width;
$g-xs:                                              $g / 8;
$g-sm:                                              $g / 2;
$g-md:                                              $g-sm * 3;
$g-lg:                                              $g * 2;

$o:                                                 30px;
$o-xs:                                              $o / 3;
$o-sm:                                              $o / 2;
$o-md:                                              $o-sm * 3;
$o-lg:                                              $o * 2;

$spacers: (
    0: 0,
    1: $g-xs,
    2: $o-xs,
    3: $o-sm,
    4: $g-sm,
    5: $o,
    6: $g
);

$transition:                                        .3s;


$font-size-base-my:                     1.6rem;

$h2-font-size-big:                      5rem;

$nav-font-size:                         $font-size-base-my;
$nav-font-size-l:                       1.2rem;
$nav-font-size-xl:                      1.4rem;

$footer-font-size:                      1.4rem;

$text-color:                            #333;
$orange:                                #ff7e33;
$pink:                                  #fff4f2;

$link-color-normal:                     #f27f3d;
$link-color-hover:                      #ff5d02;

$nav-link-color:                        #333;
$gray:                                  #b3b3b3;
$btn-color-normal:                      #E6E6E6;
$btn-color-hover:                       #cccccc;

$section-radial-gradient:               radial-gradient(#FFF9F2, #FFF2F2);
$btn-bg-gradient:                       linear-gradient(to right, #FFAC56, #ff7e33, #ff7e33, #FFAC56);
$btn-white-bg-gradient:                 linear-gradient(to right, #fff, #fff, #fff, #ff7e33, #ff7e33,  #FFAC56);

$nav-divider-margin-right-x:            3.5rem;

$modal-header-padding:                  3.5rem;
$subtitle-padding-left:                 4.7rem;

$border-radius:                         .5rem;
