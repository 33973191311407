.o-error {
    background: url(../img/_dots-cirle_pattern-left.png) 0 150px no-repeat ,
                url(../img/_dots-cirle_pattern-right.png) 100% 100% no-repeat;
}

.c-error {
    width: 100%;
    padding-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__wrap {
        text-align: center;
    }

    &__title {
       margin-bottom: $o-sm;
       margin-right: 0;
    }

    &__go-home {
        margin-top: $g-sm;
        margin-right: 0;
    }
}

@include media-breakpoint-up (xs) {

    .o-error {
        background-size: 50%;
    }

    .c-error {
        height: calc(100vh - 212px);

        &__title {
            font-size: 2.4rem;
        }
    }

}

@include media-breakpoint-up (sm) {

    .o-error {
        background-size: 40%;
    }

    .c-error {
        height: calc(100vh - 130px);

        &__title {
            font-size: 2.4rem;
        }
    }

}

@include media-breakpoint-up (md) {

    .o-error {
        background-size: 30%;
    }

    .c-error {
        &__title {
            font-size: 3.8rem;
        }
    }

}

@include media-breakpoint-up (lg) {

    .o-error {
        background-size: 20%;
    }

    .c-error {
        &__title {
            font-size: 4.4rem;
        }
    }

}

@include media-breakpoint-up (xl) {

    .o-error {
        background-size: auto;
    }

    .c-error {
        &__title {
            font-size: 5rem;
        }
    }

}