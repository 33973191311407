*:focus {
    outline: none !important;
}

html {
    font-size: 10px;

    font-family: 'GothamPro', sans-serif;

   
} 

.fixed {
    &, body {
       overflow-y: hidden; 
    }
}

body {
    position: relative;

    display: flex;
    flex-direction: column;
    min-height: 100vh;

    background-color: $body-bg;
    fill: $body-color;
    color: $text-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.fixed {
        overflow-y: hidden;
    }

}

main {
    flex-grow: 1;
}

img {
    max-width: 100%;
    height: auto;
}

button,
.btn,
a {
    transition:
        color $transition,
        background $transition,
        border-color $transition,
        fill $transition,
        opacity $transition;
    // outline: none !important;
}

textarea {
    resize: vertical;
}

abbr[title] {
    text-decoration: none;
    border-bottom: 0;
    cursor: inherit !important;
}
