.o-block-info {
	max-width: 625px;
	box-sizing: content-box;

	.subtitle {
		position: relative;

		font-size: $font-size-base-my;
		font-weight: $font-weight-normal;

		margin-bottom: 25px;
		padding-left: $subtitle-padding-left;

		&:before {
			content:'';

			position: absolute;
			top: 0;
			left: 0;

			width: 30px;
			height: 20px;

			background: url(../img/svg/dots.svg) center center no-repeat;

		}
	}

	.description {
		margin-bottom: $o;
	}

	.list-work {

		.title-item {
			position: relative;

			font-weight: $font-weight-bold;

			margin-top: 22px;
			margin-bottom: 12px;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: -45px;

			}
		}
	}
}

@media screen and (max-width: 1660px) {
	
	.col-right {

		.o-block-info {
			padding-left: 50px;
		}
	}
}