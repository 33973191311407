*:focus  {
	outline: none !important;

}
.btn {
	padding: 15px 40px;

	background-image: $btn-bg-gradient;
	background-size: 300% 100%;

	border: none;
	border-radius: $border-radius;

	margin-top: 60px;
	margin-right: 20px;

	font-size: 16px;

	transition: all .5s ease-in-out;

	&-download {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border: none;
		border-radius: 50%;
		width: 58px;
		height: 58px;
		background: linear-gradient(to right, #FFAC56, #ff7e33);
		pointer-events: none;
		box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.8);
		transition: $transition;

		&-img {
			width: 25px;
		}
		
	}

	&-b2b {
		margin-top: 0 !important;
	}

	&:focus{
		box-shadow:  none !important;
	}

	&.btn-link{
		text-decoration: none;
		color: $white;
		font-size: 1.6rem;
		font-weight: $font-weight-middle;
	}

	&.btn-white{

		position: relative;

		&.btn-link{
			color: #333;
			position: relative;
			z-index: 2;

			&.btn-link:hover {
				color: $white;
			}
		}

		&:before{
			content: '';

			position: absolute;
			top: 2px;
			left: 2px;
			right: 2px;
			bottom: 2px;

			background: $white;

			border-radius: $border-radius;

			opacity: 1;

			transition: all .5s ease-in-out;

			z-index: -1;

		}

		&:hover:before{
			right: 100%;
			opacity: 0;
		}
	}

	&:hover{
		background-position: 100% 0;
		transition: all .5s ease-in-out;

	}

}


.o-btn {
	position: relative;

	&-fixed {
		position: fixed;
		right: 0;

		z-index: 10;

		&.z-index{
			z-index: 1051;
		}

		.btn {
			margin: 0;
		
			&.c-btn-fixed {
				display: flex;
				align-items: center;
				justify-content: center;
				align-self: center;

				width: 50px;
				height: 50px;

				border-top-right-radius: 0;
				border-bottom-right-radius: 0;

				margin-right: 0;

				padding: 0;

			}
			
		}

		&-phone {
			top: calc(50% - 30px);
		}

		&-write {
			top: calc(50% + 30px);
		}

		&.scroll-hide {
			opacity: 0;
			visibility: hidden;
		}
	}

	.c-btn-close {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		width: 100%;

		background-color: $white;

		border: none;
		border-radius: $border-radius;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;

		cursor: pointer;

		opacity: 0;
		visibility: hidden;

		z-index: 2000;

		&.close-shown{
			opacity: 1;
			visibility: visible;
		}
	}

}

.swiper-btn {
	cursor: pointer;
	margin-right: 20px;

	.slide-btn-circle-svg {
		stroke: $btn-color-normal;
		transition: .1s;
	}

	.slide-btn-arrow-svg {
		fill: $btn-color-normal;
		transition: .2s;

	}

	&:hover .slide-btn-arrow-svg{
		fill: $orange;
	}

	&:hover .slide-btn-circle-svg{
		stroke: $btn-color-hover;
	}

	&:active .slide-btn-circle-svg{
		stroke: $orange;
	}

	.white-slide-btn-circle-svg {
		stroke: $white;
	}

	&:hover .white-slide-btn-circle-svg {
		stroke: $white;
	}

	.white-btn-svg {
		fill: $white;
	}
}





.btn-circle {
	cursor: pointer;

	.btn-circle-svg{
		stroke: $btn-color-normal;
		transition: .1s;

	}

		&:hover .btn-circle-svg{
			stroke: $btn-color-hover;
		}

		&:active .btn-circle-svg{
			stroke: $orange;
		}

		.btn-arrow-svg{
			fill: #E6E6E6;
			transition: $transition;
		}

		&:hover .btn-arrow-svg{
			fill: $orange;
		}
}



@include media-breakpoint-down(laptop) {
	.btn {
		margin-top: 30px;

		&-download {
			margin: 0;
		}
	}
}

@include media-breakpoint-down(xs) {

	.btn {
		padding: 12px 35px;

		&.btn-link {
			font-size: 1.3rem;

		}
	}

	.o-btn {

		&-fixed {

			opacity: 0;
			visibility: hidden;

			transition: $transition;

			.btn {
			
				&.c-btn-fixed {

					width: 40px;
					height: 40px;

				}

				
			}

			&-phone {
				top: calc(50% - 30px);
			}

			&-write {
				top: calc(50% + 20px);
			}

			&.close-shown {
				opacity: 1;
				visibility: visible;
			}

			&.scroll-shown {
				opacity: 1;
				visibility: visible;
			}

			&.position {
				transform: translateX(100%);
			}

			&.z-index{
				z-index: 10;
			}

		}

		.c-btn-close {
			display: none;
		}

	}

	.btn-slider-svg-s {
		width: 40px;
		height: 40px;
	}

}

@media screen and (max-width: 480px) {

	.btn {

		&.btn-link {
			width: 100%;

		}
	}

}
