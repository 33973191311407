.about {
	background: url(../img/_dots-pattern-gray-left.png) left center no-repeat;
	
	position: relative;

	.about-screen {
		position: relative;

		.about-wrap {
			align-items: center;

			.video-content-wrap {
				width: 100%;
				max-width: 635px;
			}
		}

			.about-text {
				font-size: $font-size-base-my;

				max-width: 650px;

				.title-item {
					margin-bottom: 12px;
				}
			}

		.slider {
			margin-top: 20px;

			.title {
				font-size: 2rem;
				font-weight: $font-weight-bold;
			}

			.swiper-container {
				width: 100%;
			}

			.slider-wrap {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				
				.swiper-btn-wrap {
					display: flex;
					align-items: center;
				}
			}

			.swiper-wrapper {

				.swiper-slide {
					display: flex;
					align-items: center;

					&.brand {

						.link-brand {
							display: flex;
							align-items: center;
							justify-content: center;

							width: 100%;
							height: 100%;

							padding: 10px 0;

						}
						
						img {
							vertical-align: middle;
							
							transition: $transition;

						}

						img.atb {
							width: 100px;
							height: 100px;
						}
					}
				}
			}

			.swiper-btn-wrap {
				width: 250px;

				.btn-slider-svg-s {
					fill: $white;
				}
			}
		}
	}
}

.about-second {
	position: relative;
	background: url(../img/wave.png) left bottom no-repeat ,
					url(../img/_dots-pattern-white.png) right center no-repeat , 
					$section-radial-gradient;
	background-size: 100%, 32%;

	.o-about-second {

		.col-wrap-about-second-left {
			width: 45%;
		}
		.col-wrap-about-second-right {
			width: 55%;

			display: flex;
			justify-content: flex-end;
		}

		&.flex-container-element {
			align-items: center;
		}

		.about-text {
			margin-top: 0;

			.list-item {
				margin-bottom: $g-sm;
				font-size: $font-size-base-my;
			}
		}

		.features {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;

			max-width: 850px;

			&-item {
				width: 230px;
		  		height: 230px;

		  		background-color: $white;

		  		border-radius: $border-radius;
		  		margin-bottom: $g;
		  		margin-left: $g;

		  		box-shadow: 0px 3px 8px 0px rgba(128,64,0,0.08);
		  		transition: $transition;

		  		display: flex;
		  		align-items: center;

		  		&:hover {
		  			box-shadow: 0px 15px 30px 0px rgba(128,64,0,0.1);
		  		}

		  		&-content {
		  			margin: 0 auto;

		  			display: flex;
		  			flex-direction: column;

		  			max-width: 170px;

		  			p {
		  				font-size: 1.4rem;
		  			}

		  			.icon {
		  				margin-bottom: 36px;
		  			}
		  		}
			}
		}
	}
}

@include media-breakpoint-down(laptop) {

	.about {

		.about-screen  {

			.slider {
				margin-top: 0;
			}
		}
	}

	.about-second {

		.o-about-second {

			.col-right {
				display: flex;
				justify-content: center;

				.features {

					max-width: 850px;

					&-item {
						width: 200px;
						height: 200px;

				  		&-content {

				  			.icon {
				  				margin-bottom: $g-sm;

				  				img {
				  					width: 40px;
				  				}
				  			}
				  		}
				  	}
				}
			}
		}
	}

}

@include media-breakpoint-down(xl) {

	.about {

		.col-left {
			width: 40%;
		}

		.col-right {
			width: 60%;

			.o-block-info {
				padding-left: 70px;
			}
		}

		.about-screen {

			.about-wrap {

				.video-content-wrap {
					// height: 260px;

				}

				.slider {
					margin-top: $g-sm;
				}
			}
		}
	}

	.about-second {

		.about-wrap {

			.col-wrap {

				&-about-second-left {
					width: 55%;

					.about-text {
						margin-left: 0;

						.title {
							max-width: 500px;
						}
					}

				}

				&-about-second-right {
					width: 45%;

					.features {
						max-width: 850px;

						margin-right: -15px;

						&-item {
					  		margin: $o-sm;
					  	}
					}
				}
			}
		}
	}

}

@include media-breakpoint-down(lg) {

	.about {
		background: none;

		&-wrap {

			&.flex-container-element {
				flex-direction: column-reverse;
			}
		}

		.col-left {
			width: 100%;

			display: flex;
			justify-content: center;

			margin-bottom: $o-lg;
		}

		.col-right {
			width: 100%;

			display: flex;
			justify-content: center;

			margin-bottom: $o-lg;

			.o-block-info {
				padding-left: 0;
			}
		}

		.about-screen {

			.about-wrap {

				.video-content-wrap {
					// height: 350px;

				}
			}
		}
	}

	.about-second {

		.o-about-second {

			&.flex-container-element {
				flex-direction: column;

			}

			.col-wrap {

				&-about-second-left {
					width: 100%;

					.about-text {
						margin-left: 150px;
					}

				}

				&-about-second-right {
					width: 100%;

					margin-left: -120px;

					padding-top: $g;

					.features {
						margin-right: 0;
					}
				}
			}
		}
	}

}

@include media-breakpoint-down(md) {

	.about {

		&-wrap {

			.col-right {

				.about-text {

					h2.title {
						max-width: 515px;
					}
				}
			}
		}
	}
	
	.about-second {

		.about-wrap {

			.col-wrap {

				&-about-second-left {

					.about-text {
						margin-left: 20px;

					}

				}

				&-about-second-right {

					margin-left: 0;

					.features {
						max-width: 100%;

						justify-content: center;

						.features-item {
					  		margin: $o-xs;
					  		
					  	}
					}
				}
			}
		}
	}

}

@include media-breakpoint-down(sm) {

	.about {

		.about-screen {

			.about-wrap {

				h2.title {
					max-width: 440px;
				}

				.video-content-wrap {
					// height: 295px;

				}
			}

			.slider {

				.slider-wrap {
					flex-direction: column-reverse;

					.swiper-company-logo {
						margin: $o-xs 0;
					}

				}

				.swiper-wrapper {
					align-items: center;
				}

				.swiper-wrapper {

					.swiper-slide {

						&.brand {

							img.atb {
								width: 60px;
								height: 60px;
							}
						}
					}
				}
			}
		}
	}

	.about-second {
		background-size: 100%, 50%;

		.o-about-second {

			&.flex-container-element {

				.col-wrap {

					&-about-second-left {

						.about-text {
							margin-left: 0;

						}

					}

					&-about-second-right {

						margin-left: 0;

						.features {
							// max-width: 100%;

							.features-item {
								margin: $o-sm;
								
							}
						}
					}
				}
			}
		}
	}	

}

@include media-breakpoint-down(xs) {

	.about {

		.col-left {
			margin-bottom: $g;
		}

		.about-screen {

			.about-wrap {

				h2.title {
					max-width: 370px;
				}
			}
		}
	}

	.about-second {

		.about-wrap {

			.col-right {

				.features {
					justify-content: center;
					// flex-direction: column;

					margin-left: 0;

					.features-item {
						margin: $o-xs;

						width: 180px;
						height: 180px;

						.features-item-content {
							p {
								font-size: 1.2rem;
							}
							
						}

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}

}

@media screen and (max-height: 840px) and (min-height: 760px) and (min-width: 1440px) {

	.about-second {
		.flex-container {
			padding-top: 190px;
		}
	}

}

@media screen and (max-height: 930px) and (min-height: 840px) and (min-width: 1440px) {

	.about-title {
		font-size: 3.6rem;
	}

}