.lg-actions .lg-next, 
.lg-actions .lg-prev {
	border-radius: 50%;

	width: 42px;
	height: 42px;

	&:hover {
		color: $orange;
	}
}