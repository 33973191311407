.main-screen {
	background: url(../img/_dots-pattern-gray-right.png) no-repeat;

	background-size: 28%;
	background-position: 100% 50%;

	position: relative;
	overflow: hidden;

	& .fp-tableCell {
		width: 100%;
	}

	&-overlay {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		svg {
			height: 100%;
			// width: 100%;

		}

	}

	.main-screen-wrap {
		position: relative;
		width: 100%;

	}

	.c-main-screen-catalog {
		position: absolute;
		right: $g-sm;
		top: 260px;
		
	}

}

.c-main-screen-catalog {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	width: 365px;

	box-sizing: border-box;

	background: $section-radial-gradient;
	border-radius: $border-radius;

	padding: 40px 0 25px 43px;

	color: $nav-link-color;
	font-size: $font-size-base-my;
	font-weight: $headings-font-weight;

	box-shadow: none;
	cursor: pointer;

	transition: $transition;

	.catalog-text{
		font-size: 2rem;
		font-weight: $font-weight-bold;

	}

	.see-catalog {
		display: block;

		text-decoration: none;

		color: $orange;
		font-size: $font-size-base-my;
		font-weight: 500;

		margin-top: 1.6rem;
	}

	&:hover {
		box-shadow: 0px 8px 15px 0px rgba(128,64,0,0.08);
		background: $white;

	}

}

@media screen and (max-width: 1430px) {

	.main-screen {

		&-overlay {
			left: 0;
		}

	}

}



@media screen and (max-width: 1290px) {

	.main-screen {

		&-overlay {
			left: -70px;
		}
		
	}

}



@include media-breakpoint-down (xl) {

	.main-screen {
		padding:  135px 0;
		display: flex;

		.c-main-screen-catalog{
			top: 110px;
			
		}

	}

}


@include media-breakpoint-down (lg) {

	.main-screen {
		background-size: 20%;
		background-position: 100% 30%;

		&-overlay {
			left: -280px;
		}

	}

}


@media screen and (max-width: 1120px) {

	.main-screen {

		&-overlay {
			left: -340px;
		}

	}

}

@media screen and (max-width: 1080px) {

	.main-screen {

		&-overlay {
			left: -360px;
		}

	}

}

@media screen and (max-width: 1030px) {

	.main-screen {

		&-overlay {
			left: -240px;
		}
		
	}

}

@include media-breakpoint-down (md) {

	.main-screen {

		background-position: 100% 20%;

		.c-main-screen-catalog{
			display: none;
			// top: 85%;
			// left: $g-sm;

			// box-shadow: 0px 0px 15px 0px rgba(128,64,0,0.08);
			
		}

		&-overlay {
			left: -280px;
		}

	}

}

@media screen and (max-width: 940px) {

	.main-screen {

		&-overlay {
			left: -160px;
		}
	}

}

@media screen and (max-width: 820px) {

	.main-screen {

		&-overlay {
			left: -180px;
		}

	}

}

@include media-breakpoint-down (sm) {

	.main-screen {
		background-size: 28%;
		background-position: 100% 50%;

		padding-bottom: 0;

		.flex-container {
			padding-top: 0;

			.btn-link {
				width: auto;
			}
		}

		&-overlay {
			left: 0;
		}		

	}

}

@media screen and (max-width: 675px) {

	.main-screen {

		&-overlay {
			left: -20px;
		}
		
	}

}

@media screen and (max-width: 620px) {

	.main-screen {

		&-overlay {
			left: -40px;
		}
		
	}

}


@media screen and (max-width: 480px) {

	.main-screen {
		background: $section-radial-gradient;
		
		.main-screen-overlay {
			display: none;
		}

		.flex-container {

			.btn-link {
				margin-left: 0;
			}
		}

	}

}

@media (orientation: landscape) and (max-width: 1439.98px) {
	
	.main-screen {
		min-height: 100vh;
	}

}

@media screen and (max-height: 840px) and (min-height: 760px) and (min-width: 1440px) {

	.main-screen {
		min-height: 100vh;

		.flex-container {
			height: 100vh;
		}
	}

}