.contacts {
	position: relative;
	background: url(../img/_dots-pattern-contacts.png) left bottom no-repeat;

	&-wrap {
		width: 100%;
		position: relative;

		.o-block-info {
			width: 100%;

			.btn{
				margin-top: 40px;
			}

		}

		.row-blocks {
			margin-bottom: $g;

			&-catalog {
				display: flex;
				justify-content: flex-end;
			}
		}
		
		.c-contacts-block {
			position: relative;
			padding-left: 135px;
			box-sizing: padding-box;

			.icon-contacts {
				position: absolute;
				top: 0;
				left: 55px;
			}

			.phone-number {
				font-size: $font-size-base-my;

				margin-bottom: 1rem;
				margin-right: 1rem;

				display: inline-block;
			}

			p .phone-number {
				margin-bottom: 0;
			}

			&.block-catalog {
				position: absolute;
				left: -520px;
				bottom: 105px;
				padding-left: 0;
				padding-right: 20px;

				display: flex;
				justify-content: flex-end;

			}
		}
	}

	.footer {
		position: absolute;
		left: 0;
		right: 0;
		bottom: $o-lg;
		padding: 0 $g-sm;

		.copyrate {
			font-size: $footer-font-size;
			color: #999;
			width: 40%;
		}

		.c-ideil {
			width: 40%;
			text-align: right;

			.c-ideil-link {
				color: $gray;
				font-size: 14px;

				img {
					margin-top: -2px;
				}
			}
		}
	}

}

@include media-breakpoint-down (laptop) {

	.contacts {

		.flex-container {
			padding-top: 100px;
		}

		&-wrap {

			.row-blocks:last-child {
				margin-bottom: $g-lg;
			}

			.c-contacts-block {
				
				&.block-catalog {
					left: -440px;
					bottom: 135px;
				}
			}
		}

		.footer {
			bottom: $o;
		}
	}

}

@include media-breakpoint-down (xl) {

	.contacts {

		&-wrap {

			.row-blocks:last-child {
				margin-bottom: $o-lg;
			}
		
			.c-contacts-block {
				padding-left: $o * 3;

				.icon-contacts {
					left: $g-sm;
				}

				&.block-catalog {
					position: static;
				}
			}
		}

		.footer {
			bottom: $o-lg;
		}
	}

}

@include media-breakpoint-down (lg) {

	.contacts {

		&-wrap {
			position: relative;

			.row-blocks:first-child {
				margin-top: $o-lg;

			}
		
			.c-contacts-block {
				position: relative;

				.icon-contacts {
					left: 0;
				}

			}

			.row-blocks-catalog {
				position: absolute;
				top: -245px;
				right: $g;

				.block-catalog {
					padding-right: 0;
				}
			}

		}

		.footer {

			.btn-up {
				svg {
					fill: $white;
				}
			}
		}
	}

}

@include media-breakpoint-down (md) {

	.contacts {

		.flex-container {
			padding-top: $g-lg;
		}

		&-wrap {

			.row-blocks {
			
				.c-contacts-block {
					padding-left: 70px;

					.icon-contacts {
						left: 0;
					}

				}

			}

			.row-blocks-catalog {
				position: static;

				display: flex;
				justify-content: flex-start;

				margin-bottom: $o-lg;

				.c-main-screen-catalog {
					display: flex;

				}

				.block-catalog {
					padding-left: 20px;
				}

				&:last-child {
					margin-bottom: 80px;
				}
			}
		}
	}

}

@include media-breakpoint-down (sm) {

	.contacts {

		.flex-container {
			padding-bottom: 130px;
		}

		&-wrap {

			.row-blocks {
				margin-bottom: 0;

				&:last-child {
					margin-bottom: 0;
				}

				.c-contacts-block {
					margin-bottom: $g;
				}
			}
		}
	}

}

@include media-breakpoint-down (xs) {

	.contacts {

		.flex-container {
			padding-bottom: 220px;
		}

		&-wrap {

			.c-contacts-block {

				.phone-number {

					display: block;
				}
			}
		}

		.footer {

			.copyrate {
				width: 100%;
			}

			.c-ideil {
				width: 100%;

				text-align: center;
			}
		}
	}

}

@media screen and (max-width: 480px) {

	.contacts {

		&-wrap {

			.btn {
				width: auto;
			}

			.c-contacts-block {

				.c-main-screen-catalog {
					width: 260px;
					height: 140px;

					padding: $o 0 13px $o;

					.catalog-text {

						.title-catalog {
							font-size: 1.4rem;
						}

						.see-catalog {
							font-size: 1.2rem;
						}
					}

					.logo-catalog {

						.logo-catalog-svg {
							width: 70px;
						}
					}
				}

				&.block-catalog {

					display: flex;
					justify-content: flex-end;

				}
			}
		}
	}

}

@media screen and (max-height: 930px) and (min-height: 840px) and (min-width: 1440px) {

	.contacts {

		.flex-container {
			padding-top: 100px;
		}

		&-wrap {

			.row-blocks {
				margin-bottom: $o-xs;

				&:last-child {
					margin-bottom: $g-sm;
				}
			}

			.c-contacts-block {
				
				&.block-catalog {
					bottom: $g;
				}
			}

		}

		.footer {
			bottom: $o;

		}
	}

}

@media screen and (max-height: 840px) and (min-height: 760px) and (min-width: 1440px) {
	
	.contacts {

		.footer {
			bottom: 100px;

		}
	}

}