.page-wrap {
	background: url(../img/_dots-cirle_pattern-left.png) 0 8% no-repeat ,
					url(../img/_dots-cirle_pattern-left.png) 0 36% no-repeat ,
					url(../img/_dots-cirle_pattern-left.png) 0 72% no-repeat ;
}

.about-us-block {
	display: flex;
	flex-direction: row;
	align-items: center;

	margin-top: $g * 5;
	margin-bottom: $g * 5;

	&-info {
		padding-left: $g-lg;
	}

	&.about-us-main {
		margin-top: $o;
		margin-bottom: $g * 5;
	}


}

.about-us-block:last-child {
	margin-bottom: 0;
}

.awards {

	.col-left-award-title {
		width: 30%;
	}

	.col-right-awards {
		width: 70%;

		.slide-awards {
			display: flex;
			justify-content: center;
			align-items: center;

		}

	}

}

.produce {
	background: url(../img/_dots-cirle.png) 67% 100% no-repeat;

	position: relative;

	width: 100%;

	margin-bottom: 100px;

	padding-bottom: 100px;

	.produce-wrap {
		display: flex;
		align-items: center;
	}

	& .col-wrap-produce-left {
		width: 45%;

		padding-right: $g * 4;

		.o-block-info {

			.subtitle-produce {
				margin-top: 35px;
				margin-bottom: $o;
			}

		}

		
	}

	& .col-wrap-produce-right {
		position: relative;

		width: 55%;

		.produce-img-wrap {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;

			.middle-img:first-child {
				margin-right: $g;
			}

		}

	}

	.swiper-produce-btn {
		position: absolute;
		left: 0;
		bottom: 0;

		z-index: 10;
		
	}

	.swiper-produce {
		position: relative;
		pointer-events: none;

	}

}

.produce-two {
	width: 100%;

	.produce-two-wrap {
		align-items: center;

		.col-wrap-produce-two-left {
			position: relative;

			width: 55%;
			padding-bottom: $o * 3;

			.slide {
				width: calc(32% - 12px);

				img {
					width: 100%;
				}
			}

			.slide-lg {
				width: calc(68% - 15px);

				img {
					width: 100%;
				}
			}

		}

		.col-wrap-produce-two-right {
			padding-left: $g-lg;

		}
	}

}

.swiper-btn-and-pagination {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	width: 100%;

	position: absolute;
	left: 0;
	bottom: 0;

	.pagination-produce-two {
		position: relative;
		margin-right: -15px;
	}
}

.staff {
	background: url(../img/_dots-pattern-white.png) right center no-repeat , $section-radial-gradient;

	padding-top: $o * 5 - 10px;
	padding-bottom: $o * 6;

	h2.title {
		margin-bottom: 0;
	}

	.swiper-staff {
		position: relative;

		margin-top: $o-lg;

		padding-bottom: $o * 5 - 10px;
	}

	& .person {

		&-item {
			position: relative;

			overflow: hidden;

			background-color: $white;

			

			padding: $o-sm;
			padding-bottom: 100px;

			border-radius: $border-radius;
			box-shadow: 0px 3px 8px 0px rgba(128,64,0,0.08);

			transition: $transition;

			&:hover {
				box-shadow: 0px 15px 30px 0px rgba(128,64,0,0.1);
			}

			&-info {
				display: flex;
				flex-direction: column;
				justify-content: center;

				position: absolute;
				top: 100%;
				left: 0;
				right: 0;

				background-color: $white;

				padding: $o-sm;

				transform: translateY(-140px);

				transition: $transition;

				.name {
					height: 130px;

					padding-top: $g-sm;
				}

			}

			&:hover .person-item-info {
				transform: translateY(-100%);

			}


			&-contacts {
				margin-bottom:  $g-sm;

				& .email {
					font-size: $font-size-base-my;

					margin-top: $o-xs;
				}
				
			}
			
		}

		&-img-wrap {
			margin-bottom: $g + 10px;
		}

		&-number {

			& .number {
				font-size: $font-size-base-my;
			}
		}

	}

	.swiper-btn-wrap-staff {
		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translateX(-50%);
	}

}

.certificates {

	.certificates-wrap {
		align-items: center;

		.col-wrap-certificates-left {
			padding-right: $g * 4 - 10px;
		}

		.col-wrap-certificates-right {

			.swiper-certificates {
				position: relative;

				padding-bottom: $o * 3;

			}

			.pagination-certificates {
				position: relative;
				margin-right: 20px;
			}

		}

	}

}

.partners {
	background: url(../img/_dots-pattern-white.png) right center no-repeat ,
					url(../img/_dots-cirle_pattern-left-white.png) 0 20% no-repeat ,
					$section-radial-gradient;

	padding: $o * 6;

	h2.title {
		margin-bottom: $o-lg;
	}

	&-wrap {
		max-width: 1100px;

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;

		margin: 0 auto;

		.partners-item {
			display: flex;
			justify-content: center;
			align-items: center;

			width: 230px;
			height: 230px;

			background-color: $white;

			margin-right: $g-sm;
			margin-left: $g-sm;
			margin-bottom: $o-lg;

			border-radius: $border-radius;

			box-shadow: 0px 3px 8px 0px rgba(128,64,0,0.08);

			transition: $transition;

			&:hover {
				box-shadow: 0px 15px 30px 0px rgba(128,64,0,0.1);
			}

		}

	}

	&-item {
		img {
			max-width: 90%;
		}
	}

}

@include media-breakpoint-down (laptop) {

	.produce {

		& .col-wrap-produce-left {

			padding-right: $o * 3;
		}

	}

	.produce-two {

		.produce-two-wrap {

			.col-wrap-produce-two-left {
				padding-right: 0;

				.slide {
					width: calc(32% - 13px);
				}

				.slide-lg {
					width: calc(68% - 17px);
				}

			}

			.col-wrap-produce-two-right {
				padding-left: $o-lg;
	
			}

		}

	}

	.certificates {

		.certificates-wrap {

			.col-wrap-certificates-right {

				.pagination-certificates {
					margin-right: -15px;
				}

			}

		}

	}

}

@include media-breakpoint-down (xl) {

	.about-us-block {

		margin-top: $o * 5;
		margin-bottom: $o * 5;

		&-info {
			padding-left: $o-lg;
		}

	}

	.produce {

		& .col-wrap-produce-left {

			padding-right: $o-lg;
		}

		& .col-wrap-produce-right {

			.produce-img-wrap {

				.img-right-block {
			
					.big-horizontall-img {
						max-height: 220px;
					}
				}
			}

			.swiper-btn {

				.btn-slider-svg-s {
					background-color: #fff;
				}
				
			}

		}

		

	}

	.produce-two {

		.produce-two-wrap {

			.col-wrap-produce-two-left {
				padding-right: 0;

				.slide {
					width: calc(32% - 14px);
				}

				.slide-lg {
					width: calc(67% - 16px);
				}
			}

			.col-wrap-produce-two-right {
				padding-left: $g;
	
			}

		}

	}

	.staff {
		padding-top: $o * 3;
		padding-bottom: $g * 3;
	}

	.swiper-btn-and-pagination {

		.pagination-produce-two {
			margin-right: -10px;
		}
	}

	.certificates {

		.certificates-wrap {

			.col-wrap-certificates-left {
				padding-right: $o-lg;
			}

		}

	}

	.partners {
		padding: $o * 6;
		padding-top: $g * 3;
		padding-bottom: $o-lg;
	}

}

@include media-breakpoint-down (lg) {

	.page-wrap {
		background-position: 0 8%, 0 36%;
	}

	.about-us-block {
		flex-direction: column-reverse;

		margin-top: $o * 5;
		margin-bottom: 0;

		&-info {
			padding-left: 0;

			margin-bottom: $g;
		}

		.col-left {
			.news-image {
				margin-bottom: 0;
			}
		}
	}

	.awards {

		.col-left-award-title {
			width: 100%;

			text-align: center;

			margin-bottom: $g-sm;
		}

		.col-right-awards {
			width: 100%;

			.slide-awards {
				display: flex;
				justify-content: center;
				align-items: center;
			}

		}

	}

	.produce {
		background-position: 100% 10%;

		padding-bottom: 0;

		& .col-wrap-produce-left {
			width: 100%;

			padding-right: 0;

			margin-bottom: $g;

			.o-block-info {

				.subtitle-produce {
					margin-top: 35px;
					margin-bottom: $o;
				}

			}
			
		}

		& .col-wrap-produce-right {
			width: 100%;

			margin-left: -20px;

			.produce-img-wrap {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;

				.middle-img:first-child {
					margin-right: $g;
				}

			}

		}

		& .col-wrap-produce-right {

			.produce-img-wrap {


				.img-right-block {
			
					.big-horizontall-img {
						max-height: 330px;
					}
				}
			}

		}

		.swiper-produce-btn {
			left: 20px;
			
		}

		.swiper-produce {
			position: relative;
			pointer-events: none;

		}

	}

	.produce-two {

		.produce-two-wrap {
			flex-direction: column-reverse;

			.col-wrap-produce-two-left {
				width: 100%;
				padding-bottom: $o * 3;
				padding-right: 0;

				.slide {
					width: calc(32% - 14px);

				}

				.slide-lg {
					width: calc(68% - 16px);

				}

			}

			.col-wrap-produce-two-right {
				padding-left: 0;

				margin-bottom: $g;

				.o-block-info {
					padding-left: 0;
				}

			}

		}


	}

	.swiper-btn-and-pagination {

		.pagination-produce-two {
			margin-right: -15px;
		}
	}

	.staff {

		padding-top: $o * 3;
		padding-bottom: $o * 3;

		.swiper-staff {

			padding-bottom: $g * 3;
		}

	}

	.certificates {

		.certificates-wrap {

			.col-wrap-certificates-left {
				margin-bottom: $g;
			}

			.col-wrap-certificates-right {

				.pagination-certificates {
					margin-right: $o-lg;
				}

				.swiper-certificates {
					
					.swiper-btn-and-pagination {
						width: 100%;
					}
				}

			}
		}

	}

	.partners {
		padding-top: $o * 3;
		padding-bottom: $o;
		padding-left: 0;
		padding-right: 0;
	}

}

@include media-breakpoint-down (md) {

	.page-wrap {
		background-position: 0 8%, 0 32%;
	}

	.about-us-block {

		margin-top: 100px;

		&.about-us-main {
			margin-top: $o;
		}

	}

	.produce {

		& .col-wrap-produce-right {

			margin-left: 0;

		}

		& .col-wrap-produce-right {

			.produce-img-wrap {

				.img-right-block {
			
					.big-horizontall-img {
						max-height: 253px;
					}
				}
			}

		}

		.swiper-produce-btn {
			left: 0;
		}
	}

	.produce-two {

		.produce-two-wrap {

			.col-wrap-produce-two-left {
				.slide {
					width: calc(32% - 13px);

				}

				.slide-lg {
					width: calc(68% - 17px);

				}

			}

		}

	}

	.staff {

		padding-top: $o-lg;
		padding-bottom: $o-lg;

		.swiper-staff {

			padding-bottom: $o * 3;
		}
		
	}

	.certificates {

		.certificates-wrap {

			.col-wrap-certificates-left {
				padding-right: 0;

			}

			.col-wrap-certificates-right {

				.pagination-certificates {
					margin-right: $g-xs;
				}

				.swiper-certificates {

					.certificates-item {
						text-align: center;
					}
					
					.swiper-btn-and-pagination {
						width: 103%;
					}
				}

			}
		}

	}

	.partners {
		background-position: 100% 50%, 0 20%;
		padding: $o-lg 0 $g-sm;
	}

}

@include media-breakpoint-down (sm) {

	.about-us-block {

		&.about-us-main {
			margin-top: 10px;
		}

	}

	.produce {

		& .col-wrap-produce-right {

			.produce-img-wrap {

				.img-right-block {
			
					.big-horizontall-img {
						max-height: 186px;
					}
				}
			}

		}

	}

	.produce-two {

		.produce-two-wrap {

			.col-wrap-produce-two-left {
				.slide {
					width: calc(32% - 7px);

				}

				.slide-lg {
					width: calc(68% - 7px);

				}

			}

		}

	}

	.swiper-btn-and-pagination {

		.pagination-produce-two {
			margin-right: -8px;
		}
	}

	.staff {

		& .person {

			&-number {

				& .number {
					font-size: 1.4rem;
				}
			}

			&-item {

				&-info {

					.name {
						height: 100px;

						margin-bottom: $g-sm;
					}

				}

				.number {
					display: inline-block;
				}

				.email {
					a {
						font-size: 1.4rem;
					}
				}

			}

		}

	}


	.certificates {

		.certificates-wrap {

			.col-wrap-certificates-right {

				.swiper-certificates {
					
					.swiper-btn-and-pagination {
						width: 102%;
					}
				}

			}
		}

	}

	.partners {
		padding-bottom: $g;

		&-wrap {

			.partners-item {
				margin-right: $o-xs;
				margin-left: $o-xs;
				margin-bottom: $o;
			}
		}
		
	}

}

@include media-breakpoint-down (xs) {

	.about {
		background: none;
	}

	.page-wrap {
		background: none;
	}

	.about-us-block {
		margin-top: $o-lg;
		margin-bottom: $o-lg;

	}

	.produce {
		background: none;
	}

	.produce-two {

		.produce-two-wrap {
			flex-direction: column-reverse;

			.col-wrap-produce-two-left {
				padding-bottom: $o-lg;

				.slide {
					width: calc(32% - 6px);

				}

				.slide-lg {
					width: calc(68% - 8px);

				}

				.swiper-btn {
					display: none;
				}

				.pagination-produce-two {
					margin: 0 auto;
				}

			}

		}

	}

	.certificates {

		.certificates-wrap {

			.col-wrap-certificates-right {

				.swiper-certificates {
					padding-bottom: $o-lg;

					.certificates-item {
						display: flex;
						justify-content: center;
					}

					.pagination-certificates {
						margin: 0 auto;
					}
				}

				.swiper-btn {
					display: none;
				}

			}
		}

	}

	.partners {
		background-position: 100% 80%, 0 20%;

		h2.title {
			margin-bottom: $o;
		}

		&-wrap {

			.partners-item {
				width: 170px;
				height: 170px;

				margin-bottom: $g-sm;

				img {
					width: 90px;
				}
			}
		}
		
	}

}
@media screen and (max-width: 490px) {

	.staff {
		.swiper-staff {
			padding-bottom: 70px;
		}

		& .person {

			&-item {
				max-width: 365px;

				margin: 0 auto;
			}

		}

	}

}