.burger-menu {
	display: none;
}

.o-header-wrap {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.logo {
		width: 220px;

		&-svg {
			width: 140px;

			transition: $transition;

			&-s {
				width: 110px;
			}
		}

	}
	
	.hed-inner-wrap {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		width: calc(100% + 220px);

		.nav {
			display: flex;

			margin-left: 240px;

			&-link {
				color: $nav-link-color;
				font-size: $nav-font-size;
				font-weight: $headings-font-weight;
				margin-right: $nav-divider-margin-right-x;
				align-self: center;
				position: relative;

				&:last-child {
					margin-right: 0;
				}

				&:hover {
					color: $orange;

					&::after {
						opacity: 1;
					}
				}

				&::after{
					content: '';
					width: 20px;
					height: 20px;
					background: url(../img/svg/menu-link-hover-dots.svg) no-repeat center;
					display: block;
					position: absolute;
					top: 20px;
					left: calc(50% - 8px);
					opacity: 0;	
					transition: $transition;		
				} 

				&.is-active {
					color: $orange;
					transition: $transition;

					&::after {
						opacity: 1;
					}
				}
			}
		}
		
		.social-and-lang-wrap {
			width: 220px;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;

			.c-social, .c-lang{
				align-self: center;
			}

			.c-social {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;

				margin-right: $g;

				.social-item-link{
					display: inline-block;
					padding: 0 10px;

					.social-item-link-svg{
						fill: #b3b3b3;
						transition: $transition;
					}

					&.facebook{
						text-align: right;

						&:hover .social-item-link-svg {
							fill: #3b5998;
						}
					}

					&.instagram{

						&:hover .social-item-link-svg {
							fill: #e1306c;
						}
					}
					
					&.youtube{
						
						&:hover .social-item-link-svg {
							fill: #ff0000;
						}
					}

					&.google-plus{
						
						&:hover .social-item-link-svg {
							fill: #dd4b39;
						}
					}
				}
			}
		}
	}

	.c-lang {
		position: relative;

		.lang-link {
			display: inline-block;
			color: $nav-link-color;
			font-size: $nav-font-size;
			font-weight: $headings-font-weight;
			text-decoration: none;

			&.is-active {
				position: relative;
				cursor: pointer;
				padding: 2px;
				padding-bottom: 0;
				border-bottom: 2px solid #ff7e33;
				z-index: 1;
			}
		}

		&-lg {
			display: none;
		}

		&__other {
			position: absolute;
			top: ($g-xs * -1);
			right: ($o-xs * -1);
			left: ($o-xs * -1);
			background-color: $white;
			padding: 12px;
			padding-top: 45px;
			box-shadow: 0px 3px 20px 0px rgba(128, 64, 0, 0.08);
			transform: translateY(-10%);
			opacity: 0;
			pointer-events: none;
			transition: .5s;

			&.is-active {
				transform: translateY(0);
				pointer-events: auto;
				opacity: 1;
				transition: .5s;
			}

			.lang-link {
				margin-bottom: $g-xs;
			}
		}

		&:hover {

			.c-lang__other {
				transform: translateY(0);
				pointer-events: auto;
				opacity: 1;
				transition: .5s;
			}

			.c-lang__arrow {
				transform: translateY(-40%);
				opacity: 0;
			}

		}
	}

}

@include media-breakpoint-down (desktop) {

	.o-header-wrap {

		.hed-inner-wrap {
		
			.nav {

				&-link {

					&.active {
						color: $orange;
						transition: $transition;
	
						&::after {
							opacity: 1;
						}
					}
				}

			}
		}
	}	

}


@include media-breakpoint-down (laptop) {

	.o-header-wrap {

		.hed-inner-wrap {

			.nav {
				margin-left: 100px;
			}
		}
	}

}
@include media-breakpoint-down (xl) {

	.o-header-wrap {

		.hed-inner-wrap {
		
			.nav {

				&-link {
					font-size: $nav-font-size-l;
				}

			}

			.social-and-lang-wrap {

				.lang-link {
					font-size: $nav-font-size-l;
				}
			}
		}
	}

}

@include media-breakpoint-down (lg) {

	.burger-position {
		position: relative;

		.burger-menu {
			display: flex;
			flex-direction: column;

			cursor: pointer;
			
			position: absolute;
			top: 63px;
			left: $g-sm;

			width: 30px;
			height: 18px;

			transition: $transition;

			&-scroll {
				top: 41px;
			}

			.burger-layer {
				position: absolute;

				width: 30px;
				height: 2px;
				display: block;

				background-color: $gray;

				border-radius: 5px;

				transition: $transition;

				&:first-child {
					top: 0;
					&.close {
						top: 50%;
						left: -8px;
						transform: translateY(-50%);
						transform: rotate(135deg);	
					}
				}

				&:nth-child(2){
					top: 50%;
					transform: translateY(-50%);

					&.close {
						display: none;
					}
				}

				&:last-child {
					bottom: 0;

					&.close {
						top: 50%;
						left: -8px;
						transform: translateY(-50%);
						transform: rotate(-135deg);
					}
				}
			}

			&:hover .burger-layer {
				background-color: $orange;
			}
		}
	}
	
	.o-header-wrap {
		flex-direction: column;
		justify-content: center;

		.logo {
			width: 100%;

			text-align: center;

			padding-top: $o;

			&-scroll {
				padding-top: 17px;
			}

		}
		
		.hed-inner-wrap {
			display: block;
			position: absolute;
			top: 140px;
			left: 0%;

			transform: translateX(-50%);
			transform: translateY(-20%);

			width: 100%;
			height: calc(100vh - 140px);

			overflow-y: auto;

			opacity: 0;
			visibility: hidden;

			pointer-events: none;

			transition: $transition;

			&.show {
				opacity: 1;
				visibility: visible;
				pointer-events: auto;

				transform: translateY(0);

			}

			&.scroll {
				overflow-y: auto;
			}
		
			.nav {
				flex-direction: column;

				width: 100%;

				margin-top: 0;
				margin-left: 0;

				&-link {
					margin: $g-sm 0;
					font-size: $nav-font-size-xl;
				}
			}

			.social-and-lang-wrap {
				flex-direction: column;
				
				width: 100%;

				margin-right: 0;

				.c-social {
					margin: $g 0 $o;

				}

				.c-lang {
					display: none;
				}
			}
		}

		.c-lang-lg {
			display: block;

			position: absolute;
			top: 60%;
			right: $g-sm;

			transform: translateY(-50%);
		}
	}

}

@include media-breakpoint-down (sm) {

	.burger-position {

		.burger-menu {
			top: 39px;

			&-scroll {
				top: 31px;
			}
		}
	}

	.o-header-wrap {

		.logo {

			transition: $transition;

			&-svg {
				width: 80px;

			}

		}

	}

}

@include media-breakpoint-down (xs) {

	.burger-position {

		.burger-menu {
			top: 31px;
			
			.burger-layer {

				&:first-child {
					&.close {
						left: -0;
					}
				}

				&:last-child {

					&.close {
						left: -0;
					}
				}

			}
		}
	}

	.o-header-wrap {

		.logo {
			padding-top: 17px;

			&-svg {
				width: 80px;

			}

		}

		.hed-inner-wrap {
			top: 100px;

			.nav {

				&-link {
					margin: $o-xs 0;
				}
			}
		}

		.c-lang-lg {

			.lang-link {
				font-size: 1.2rem;
			}
		}
	}

}