
@font-face {
  font-family: 'GothamPro';
  src: url('../fonts/gotham/GothamPro.eot?#iefix') format('embedded-opentype'),  url('../fonts/gotham/GothamPro.otf')  format('opentype'),
      url('../fonts/gotham/GothamPro.woff') format('woff'), url('../fonts/gotham/GothamPro.ttf')  format('truetype'), url('../fonts/gotham/GothamPro.svg#GothamPro') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamPro';
  src: url('../fonts/gotham/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/gotham/GothamPro-Bold.otf')  format('opentype'),
      url('../fonts/gotham/GothamPro-Bold.woff') format('woff'), url('../fonts/gotham/GothamPro-Bold.ttf')  format('truetype'), url('../fonts/gotham/GothamPro-Bold.svg#GothamPro-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'GothamPro';
  src: url('../fonts/gotham/GothamPro-Medium.eot?#iefix') format('embedded-opentype'),  url('../fonts/gotham/GothamPro-Medium.otf')  format('opentype'),
      url('../fonts/gotham/GothamPro-Medium.woff') format('woff'), url('../fonts/gotham/GothamPro-Medium.ttf')  format('truetype'), url('../fonts/gotham/GothamPro-Medium.svg#GothamPro-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}