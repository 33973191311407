.video-wrap {
	width: 100%;
	max-width: 635px;
	height: 100%;
	max-height: 450px;

	border-radius: $border-radius;

	overflow: hidden;

	box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);

	.video-block {
		width: 100%;

		.bg-download {
			display: block;
			position: relative;

			&:before {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				background-color: #000;
				opacity: .3;
			}

			&:hover {
				.btn-download {
					box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.5);
				}
			}
		}

		.video-content-wrap {
			position: relative;
			overflow: hidden;

			.overlay-img {
				position: absolute;
				top: 0;
				
				height: 100%;

				pointer-events: none;

				opacity: 1;
				visibility: visible;

				&.hide {
					opacity: 0;
					visibility: hidden;
				}
			}


			.video-overlay {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;

				background-color: rgba(0,0,0,.2);

				opacity: 1;
				visibility: visible;

				z-index: 1;
				pointer-events: none;

				&.video-overlay-hide{
					opacity: 0;
					visibility: hidden;

				}
			}

			.video {
				width: 100%;
			}

			.btn-play {
				position: absolute;
				top: calc(50% - 30px);
				left: calc(50% - 30px);
				z-index: 2;
			}
		}
	}

	.video-subtitle {
		background-color: $white;
		text-align: center;
		padding: $o $o-xs;
		font-size: $font-size-base-my;
		font-weight: $font-weight-middle;
	}
}

@include media-breakpoint-down (xs) {

	.video-wrap {

		.video-subtitle {
			font-size: 1.4rem;

		}
	}
}