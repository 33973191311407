.swiper-pagination-bullet {
	width: 12px;
	height: 12px;

	&-active {
		background: $orange;
	}
}

.swiper-pagination-bullet {
	margin: 0 15px;
}

@include media-breakpoint-down (sm) {

	.swiper-produce {
		.swiper-slide {
			width: 100%;
		}
	}

}

@include media-breakpoint-down (sm) {

	.swiper-pagination-bullet {
		width: 8px;
		height: 8px;

	}

	.swiper-pagination-bullet {
		margin: 0 8px;
	}

}