.img-left-block{

	.big-vertical-img{
		max-width: 365px;
		max-height: 550px;

		overflow: hidden;

		margin-right: $g;
	}

}

.img-right-block{
	display: flex;
	flex-direction: column;

	width: 500px;

	.middle-img{
		max-width: 50%;
		max-height: 330px;

		overflow: hidden;
	}


	.big-horizontall-img{
		max-width: 500px;
		max-height: 330px;

		overflow: hidden;

		margin-top: $g;
	}

}

@include media-breakpoint-down (md) {

	.img-left-block{

		.big-vertical-img{

			margin-right: $g-sm;
		}

	}

	.img-right-block{

		.big-horizontall-img{

			margin-top: $g-sm;
		}

	}

	.produce {

		.col-wrap-produce-right {

			.produce-img-wrap {

				.middle-img:first-child {

					margin-right: $g-sm;
				}
			} 
		}
	} 

}

@include media-breakpoint-down (xs) {

	.img-left-block{

		.big-vertical-img{

			margin-right: $o-xs;
		}

	}

	.img-right-block{

		.big-horizontall-img{

			margin-top: $o-xs;
		}

	}

	.produce {

		.col-wrap-produce-right {

			.produce-img-wrap {

				.middle-img:first-child {
					
					margin-right: $o-xs;
				}
			} 
		}
	} 

}