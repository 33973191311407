.post-page {
	background: url(../img/_dots-cirle_pattern-right.png) 100% 25% no-repeat ,
					url(../img/_dots-cirle_pattern-left.png) 0 40% no-repeat ,
					url(../img/_dots-cirle_pattern-right.png) 100% 95% no-repeat;
}

.post-container {
	position: relative;
	
	.back {
		position: absolute;
		top: 400px;
		left: $g-sm;
	}

	.share {
		position: absolute;
		top: 500px;
		left: $g-sm;

		display: flex;
		flex-direction: column;

		&-link {

			&-svg {
				opacity: .5;
				transition: $transition;
			}

			&:hover .share-link-svg {
				opacity: 1;
			}
		}

		&-google {
			margin-top: 10px;
		}

		
	}

	.post {
		padding-top: $g;

		&-wrapper {
			max-width: 1041px;
			margin: 0 auto;
		}

		&-img {
			display: flex;
			justify-content: center;
			align-items: center;

			background-color: #DEF8FF;

			margin-bottom: 50px;
			box-shadow: 0px 6px 18px 0px rgba(0,0,0,0.1);
		}

		&-slider {
			position: relative;

			margin: $o-lg 0 110px;

			.item-post {
				cursor: zoom-in;
			}

			& .swiper-btn-next-post {
				position: absolute;
				top: 50%;
				left: -$g * 3 + 10px;

				transform: translateY(-50%);
			}

			& .swiper-btn-prev-post {
				position: absolute;
				top: 50%;
				right: -$g * 3 + 10px;

				margin-right: 0;

				transform: translateY(-50%);
			}

			.swiper-pagination {
				position: absolute;
				left: 50%;
				bottom: -55px;

				transform: translateX(-50%);
			}
		}
	}
}

.wrap-share-s {
	display: none;
}

.s-content {

	h3 {
		margin-top: $g;
		font-weight: $font-weight-bold;
	}
	p {
		margin: $g-sm 0;
	}

	.float-left {
		float: left;
		margin-right: $o-lg;
		margin-bottom: $o;
	}

	ul {
		padding-left: $g;
		margin: $g-sm 0;
		list-style: none;

		li {
			position: relative;
			padding-left: 25px;
			margin-bottom: $o-xs;

			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 7px;
				left: 0;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background-color: $orange;
			}

			&:last-child {
				margin-bottom: 0;
			}

			p {
				margin: 0;
			}
		}
	}
}

@include media-breakpoint-down (xl) {

	.post-container {

		.back {
			top: 250px;
			left: 50px;
		}

		.share {
			top: 350px;
			left: 50px;
		}

		.post {
			&-img {
				width: 77%;
				margin: 0 auto 50px;
			}

			&-slider {
				position: relative;
				margin: $o-lg 0 110px;

				& .swiper-btn-next-post {
					left: -$g-lg;
				}

				& .swiper-btn-prev-post {
					right: -$g-lg;
				}
			}
		}
	}

}

@include media-breakpoint-down (lg) {
	
	.post-container {
		.back {
			top: 230px;
			left: $g-sm;
		}

		.share {
			top: 320px;
			left: $g-sm;
		}

		.post {
			&-slider {
				& .swiper-btn-next-post {
					top: calc(100% + 45px);
					left: 0;
					padding: 0;
					border-radius: 100%;
					z-index: 5;
				}

				& .swiper-btn-prev-post {
					top: calc(100% + 45px);
					right: 0;
					padding: 0;
					border-radius: 100%;
					background-color: #fff;
					z-index: 5;
				}
			}
		}
	}

}

@include media-breakpoint-down (md) {

	.post-page {
		background: none;
	}

	.post-container {
		.back {
			top: 150px;
			left: $g-sm;
		}

		.share {
			top: 240px;
			left: $g-sm;
		}

		.post {
			padding-top: $o;
			&-img {
				margin-bottom: $o;
			}
		}
	}

	.s-content {
		.float-left {
			display: block;
			float: none !important;
			margin: $o auto;
		}
	}

}

@include media-breakpoint-down (sm) {

	.post-container {
		padding-top: $g-sm;

		.back {
			display: none;
		}

		.share {
			display: none;

		}

		.post {
			&-img {
				width: 100%;
			}

			&-wrapper {
				padding-top: 0;
			}

			&-slider {
				& .swiper-btn-next-post {
					display: none;

				}

				& .swiper-btn-prev-post {
					display: none;

				}

			}

			&-slider {
				margin: $o-lg 0 $o-lg;

				.swiper-pagination {
					bottom: -$o;

				}
			}
		}
		
	}

	.s-content {
		ul {
			padding-left: $g-sm;

			li {
				padding-left: 20px;
				
				&:before {
					top: 5px;
				}
			}
		}
	}

	.wrap-share-s {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: $g-sm;
		padding-bottom: $o;

		.share {
			display: block;

			&-link {
				margin: 0 $o-xs;

				&-svg {
					opacity: .5;
					transition: $transition;
				}

				&:hover .share-link-svg {
					opacity: 1;
				}
			}
		}
	}	

}

@include media-breakpoint-down (xs) {

	.post-container {
		padding-top: 0;

		.post {
			padding-top: 0;
		}
	}

}