.footer{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	padding: $g $g-sm;
}

.footer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	.copyrate {
		font-size: $footer-font-size;
		color: #999;
		width: 40%;
	}

	.btn-up {
		padding: 0;
		border: none;
		background: none;
		cursor: pointer;
		transition: $transition;

		&.btn-hide {
			pointer-events: none;
			opacity: 0;
			visibility: hidden;

			&-show {
				pointer-events: auto;
				visibility: visible;
				opacity: 1;

				transition: $transition;
			}
		}

	}

	.c-ideil {
		width: 40%;
		text-align: right;

		.c-ideil-link {
			color: $gray;
			font-size: $footer-font-size;

			img {
				margin-top: -2px;
			}

		}

	}

	&-main {
		display: none;
	}
	
}


@include media-breakpoint-down (xs) {
	.footer {
		flex-direction: column;
		justify-content: center;

		.copyrate , .c-ideil {
			text-align: center;
			margin-top: $g-sm;

			width: 100%;
		}

		.copyrate {
			order: 1;
		}

		.c-ideil {
			order: 2;
		}
	}
}