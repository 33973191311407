textarea:active,
textarea:focus{
	outline: none;
	box-shadow: none
}

.c-form {

	.icon-close-xs-svg {
		fill: #b3b3b3;

		transition: $transition;

		&:hover {
			fill: $orange;
		}
	}

	&-title {
		margin-bottom: $o-xs;
	}

	.form-subtitle {
		width: 100%;
		max-width: 100%;

		font-size: 1.4rem;

		margin-bottom: $o;
	}

	&-item {
		margin-bottom: $o;

		position: relative;

		& .form-control {
			outline: none;

			width: 100%;

			font-size: $font-size-base-my;
			font-weight: $font-weight-normal;
			color: $text-color;

			padding: $o $g-sm;

			border: 1px solid #e6e6e6;
			border-radius: $border-radius;

			&.textarea {
				padding-top: $g-sm;
			}

			&.is-invalid {
				border-color: $red;
			}

		}

		&__label {
			position: absolute;
			left: $g-sm;
			top: $g-sm;

			font-size: 1.6rem;

			color: #999;

			pointer-events: none;

			transition: $transition;

			&.label-active {
				top: -10px;
				left: 15px;

				padding: 0 $g-xs;

				color: $orange;
				font-size: 1.4rem;
				font-weight: $font-weight-middle;
				background-color: $white;
				
			}
		}

		.invalid-feedback {
			font-size:1.4rem;
			text-align: left;
			margin-top: 7px;
		}
	}

	.btn {
		margin: 0;

		width: 100%;
	}
}

.input-file {
	display: none;
}

// Download file
.download-file {
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	.file-label {
		position: relative;

		color: $text-color;
		font-size: 1.4rem;
		text-align: left;

		max-width: 90%;
		max-height: 22px;

		line-height: 21px;

		overflow: hidden;

		padding-left: 25px;
		margin: 0;

		cursor: pointer;

		.clip {
			position: absolute;
			top: 0;
			left: 0;

			width: 20px;
			height: 20px;

			&-svg {
				fill: #333;

				transition: $transition;
			}
		}

		&:hover {
			
			.clip {

				&-svg {

					fill: $orange;
				}
			}
		}
	}

	.file-delete {
		cursor: pointer;

		opacity: 0;
		visibility: hidden;

		margin-left: auto;

		transition: $transition;

		.icon-delete-svg {
			fill: orange;
		}

		&.show {
			opacity: 1;
			visibility: visible;
		}
	}
}

// form popup
.modal-wrap {
	max-width: 450px;

	&-right {
		margin-left: calc(100% - 510px);
	}
}

.header-group {
	border: none;

	flex-direction: column;
	align-items: center;

	padding: 20px 15px 0;

	.icon-close {
		margin: 0;
		padding: 0;

		&-xs {
			display: none;
			
		}

		position: absolute;
		right: $g-sm;
		top: $g-sm;

		cursor: pointer;

		&-lg {
			display: block;

		}

		.icon-close-xs-svg {
			fill: #b3b3b3;

			&:hover {
				fill: $orange;
			}
		}
	}
}

// form popup
.popup-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	background-color: rgba(0,0,0,.45);
	z-index: 10;

	opacity: 0;
	visibility: hidden;
	
	transition: $transition;

	&.popup-shown {
		opacity: 1;
		visibility: visible;
	}

	z-index: 1001;

}

.o-popup {
	position: relative;

	text-align: center;

	border: none;
	border-radius: $border-radius;

	box-shadow: 0px 8px 15px 0px rgba(128,64,0,0.08);

	padding: $o 50px 58px;

	.icon-close{

		&-xs {
			display: none;
			
		}

		position: absolute;
		right: $g-sm;
		top: $g-sm;

		cursor: pointer;

		&-lg {
			display: block;

		}
	}

}

@include media-breakpoint-down (xl) {

	.c-form {

		&-item {
			margin-bottom: $g-sm;

			& .form-control {
				padding: 25px $g-sm;

				&.textarea {
					padding-top: $o-sm;
				}
			}

			&__label {
				top: $o-sm;
			}
		}
	}
	
	.o-popup {
		padding: $g-sm $o $o;
	}

}

@include media-breakpoint-down (xs) {

	.modal-wrap {
		max-width: 100%;

		&-right {
			margin: 0 10px;
		}
	}

	.c-form {

		.form-subtitle {
			font-size: 1.2rem;
		}

		&-wrap {
			width: 100%;

			right: $g-sm;
			left: $g-sm;
		}

		&-item {
			margin-bottom: $g-sm;

			& .form-control {
				padding: $g-sm;

				font-size: 1.4rem;

				&.textarea {
					padding-top: $o-xs;
				}

			}

			&__label {
				top: 12px;

				font-size: 1.2rem;

				&.label-active {
					top: -8px;

					font-size: 1.2rem;
				}
			}

			.invalid-feedback {
				font-size: 1.2rem;
			}
		}
	}
	
	.o-popup {
		padding: $g-sm $o-sm;

		.icon-close{

			&-xs {
				display: block;
			}
		}
	}

}

@media screen and (max-width: 400px) {

	.c-form {

		.form-subtitle {
			font-size: 1.2rem;
		}
	}

}
